import React, { useState, useEffect } from 'react';
import { firestore, storage, analytics } from '../config/firebase-config';
import { Document, Page, pdfjs } from 'react-pdf';
import PageHeader from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/SimpleFiveColumn";
import MedianetADS from "components/MedianetADS";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import { ReactComponent as FolderIcon } from "feather-icons/dist/icons/folder.svg";
import { ReactComponent as BookIcon } from "feather-icons/dist/icons/book-open.svg";
import { ReactComponent as FileIcon } from "feather-icons/dist/icons/file-text.svg";
import { ReactComponent as CheckIcon } from "feather-icons/dist/icons/check-square.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob4 } from "images/svg-decorator-blob-8.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"
import { Helmet } from 'react-helmet';
import CardGrid from "components/cards/CardGrid";
import { Container as ContainerBase } from "components/misc/Layouts";
import { Link, Redirect } from "react-router-dom";
import "../styles/App.css";
import "styles/document_styles.css";
import LoadingAnimation from 'components/animations/Loading';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = tw(ContainerBase)`flex justify-center pt-4 mb-4`;
const DocContainer = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto pt-20`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const SecondaryButton = tw.img`inline`;

const FeaturesContainer = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-4`;

const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const FeaturesText = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const LinkText = tw.div`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 transition duration-300 text-sm ml-2 font-semibold py-1`;

const ErrorText = tw.p`ml-2 text-lg font-semibold text-gray-800 text-center my-16`;

// Portrait Styling
const PortraitContainer = tw(ContainerBase)`flex justify-center pt-4 mb-8`;
const PortraitDocContainer = tw.div`max-w-screen-xl m-0 my-8 bg-white text-gray-900 flex flex-1 flex flex-col lg:flex-row! rounded-lg`; // shadow sm:rounded-lg 
const PortraitDocument = tw.div`w-full lg:w-2/3 xl:w-2/3 p-2 pb-4 shadow sm:rounded-lg`;
const PortraitDocSidebar = tw.div`w-full lg:w-1/3 xl:w-1/3 sm:flex-1 pb-4 z-10`;
const SideBarItem = tw.div`mx-0 my-4 p-4 lg:ml-4 lg:my-0 text-gray-900 shadow sm:rounded-lg`;
const IndexContainer = tw(SideBarItem)`flex flex-col justify-start pl-6`;
const IndexTextContainer = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IndexText = styled.div`
  ${tw`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 transition duration-300 text-sm ml-2 font-semibold py-1 cursor-pointer`}
  ${props => props.active && tw`text-primary-500`}
`;
const LandscapeDocContainer = tw.div`w-full p-2 pb-4`;

const IndexHeader = tw.h1`font-black text-xl pb-1`;

const HeaderRow = tw.div`flex justify-between items-center flex-col mt-4 mb-1`;
const PagesControl = tw.div`flex bg-gray-200 px-2 py-2 rounded leading-none mx-8 mb-2`;
const PageControl = styled.div`
  ${tw`px-4 py-2 mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;
const PageControlArrows = styled.div`
  ${tw`cursor-pointer px-4 py-2 mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.disabled && tw`text-gray-100! hover:bg-gray-200 text-gray-300 hover:text-gray-300`}
  }
`;

const DecoratorBlob = styled(SvgDotPattern)`
  ${tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 fill-current text-primary-500 -z-10 left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-64 w-64 opacity-75 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-50 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;
const DecoratorBlob3 = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-56 w-56 opacity-50 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob4 = styled(SvgDecoratorBlob4)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-2/3 text-primary-500`}
`;
const DecoratorBlob5 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-50 transform translate-x-2/3 -translate-y-2/3 fill-current text-teal-300`}
`;
const DecoratorBlob6 = styled(SvgDecoratorBlob4)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 text-primary-500`}
`;
const DecoratorBlob7 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-50 transform translate-x-2/3 -translate-y-1/2 rotate-180 fill-current fill-current text-primary-500`}
`;

const feature_icons = [<FolderIcon />, <BookIcon />, <CheckIcon />, <FileIcon />];

const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

function useCurrentWidth(doc) {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [])
  if (doc !== null) {
    if (doc.Landscape === false) {
      if (width < 640) {
        // xs
        width = parseFloat(width) - (parseFloat(width) * .11)
      } else if (width < 768) {
        // sm
        width = parseFloat(width) - (parseFloat(width) * .01)
      } else if (width < 1024) {
        // md
        width = parseFloat(width) - (parseFloat(width) * .04)
      } else if (width < 1280) {
        // lg
        width = parseFloat(width) - (parseFloat(width) * .35)
      } else {
        // xl
        width = 848;
      }
      return parseFloat(width) - (parseFloat(width) * .05);
    } else {

      if (width < 640) {
        // xs
        width = width
      } else if (width < 768) {
        // sm
        width = width
      } else if (width < 1024) {
        // md
        width = parseFloat(width) - (parseFloat(width) * .1)
      } else if (width < 1280) {
        // lg
        width = parseFloat(width) - (parseFloat(width) * .2)
      } else {
        // xl
        width = 1280;
      }
      return parseFloat(width) - (parseFloat(width) * .05);
    }
  }
}

function DocumentView(props) {
  const [doc, setDoc] = useState(null);
  const [docURL, setDocURL] = useState('');
  const [features, setFeatures] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [relatedDocsFetched, setRelatedDocsFetched] = useState(false);

  const [relatedResources, setRelatedResources] = useState([]);

  const [docDoesntExist, setDocDoesntExist] = useState(false);
  const [error, setError] = useState(false);
  const [thumbnailURL, setThumbnailURL] = useState(null);

  let width = useCurrentWidth(doc);

  useEffect(() => {
    firestore.collection('resources').doc(props.match.params.id)
      .get()
      .then(document => {
        if (document.exists) {
          const data = document.data();
          // Get Document
          const docPathRef = storage.ref(data.DocumentURL);
          docPathRef.getDownloadURL().then((url) => {
            setDocURL(url);
          }).catch(function (e) {
            setError(true);
          });
          setDoc(data);
        } else {
          setDocDoesntExist(true);
        }
      })
      .catch(err => {
        analytics.logEvent('error', { location: 'Document view, fetch doc', error: err });
        setError(true);
      });
  }, [props.match.params.id]);

  useEffect(() => {
    if (doc) {
      const storage_ref = storage.ref(doc.ThumbnailURL);
      storage_ref.getDownloadURL().then((url) => {
        setThumbnailURL(url);
      }).catch(function (err) {
        analytics.logEvent('error', { location: 'Document View header social media image', error: err });
      })
    }
  }, [doc]);

  useEffect(() => {
    if (doc !== null && !relatedDocsFetched) {
      setRelatedDocsFetched(true);
      firestore.collection('resources')
        .where('Subject', '==', doc.Subject)
        .where('Unit', '==', doc.Unit)
        .where('isPublic', '==', true)
        .limit(4)
        .get()
        .then(function (querySnapshot) {
          let result = [];
          querySnapshot.forEach(function (d) {
            if (d.data().Title !== doc.Title) {
              let temp_d = d.data();
              temp_d.id = d.id;
              result.push(temp_d);
            }
          })
          if (result.length > 3) {
            result = result.slice(0, 3);
          }
          result.sort((a, b) => (a.GridOrder > b.GridOrder) ? 1 : -1);
          setRelatedResources(result);
        }).catch(err => {
          analytics.logEvent('error', { location: 'Document related resources', error: err });
          setRelatedResources([]);
        });
    }
  }, [doc]);

  useEffect(() => {
    if (doc !== null) {
      setFeatures([doc.Subject.charAt(0).toUpperCase() + doc.Subject.slice(1), doc.Unit.replace('_', ' '), doc.Topic, doc.Type]);
    }
  }, [doc]);

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setActivePage(1);
    setNumPages(numPages);
  }

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [shareButtonText, setShareButtonText] = useState('Share!');

  if (!docDoesntExist) {
    return (
      <AnimationRevealPage disabled>
        {doc ? <Helmet>
          <title>{doc.Title} for {capitalize(window.location.pathname.split('/')[3].split('-')[0]) + ' ' + capitalize(window.location.pathname.split('/')[3].split('-')[1])} | Revise Higher</title>
          <meta name="description" content={doc.Description} />
          <meta property="og:title" content={`${doc.Title} - Higher ${doc.Subject.charAt(0).toUpperCase() + doc.Subject.slice(1)} - Revise Higher`} />
          <meta property="og:description" content={doc.Description} />
          {thumbnailURL && <meta property="og:image" content={thumbnailURL} />}
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:title" content={`${doc.Title} - Higher ${doc.Subject.charAt(0).toUpperCase() + doc.Subject.slice(1)} - Revise Higher`} />
          <meta name="twitter:description" content={doc.Description} />
          {thumbnailURL && <meta name="twitter:image" content={thumbnailURL} />}
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
          :
          <Helmet>
            <title>{capitalize(window.location.pathname.split('/')[3].split('-').slice(2).join(' '))} for {capitalize(window.location.pathname.split('/')[3].split('-')[0]) + ' ' + capitalize(window.location.pathname.split('/')[3].split('-')[1])} | Revise Higher</title>
            <meta name="description" content={`${capitalize(window.location.pathname.split('/')[3].split('-').slice(2).join(' '))} for ${capitalize(window.location.pathname.split('/')[3].split('-')[0]) + ' ' + capitalize(window.location.pathname.split('/')[3].split('-')[1])}`} />
          </Helmet>}
        {doc ? <PageHeader currentSubject={doc.Subject} /> : <PageHeader />}
        {docURL && doc && !error ?
          <>
            <TwoColumn>
              <LeftColumn>
                <Heading>{doc.Title}</Heading>
                <FeaturesContainer>
                  {features.map((feature, index) => (
                    <IconWithText key={index}>
                      <IconContainer>
                        {feature_icons[index]}
                      </IconContainer>
                      { index === 0 ? <Link to={`/subjects/${doc.Subject}`}><LinkText>{feature}</LinkText></Link> : <FeaturesText>{feature}</FeaturesText>}
                    </IconWithText>
                  ))}
                </FeaturesContainer>
                <Paragraph>{doc.Description}</Paragraph>
                <Actions>
                  <PrimaryButton
                    as="a"
                    alt={document.url}
                    id='share_button'
                    onClick={() => {
                      var dummy = document.createElement('input'), text = window.location.href;
                      document.body.appendChild(dummy);
                      dummy.value = text;
                      dummy.select();
                      document.execCommand('copy');
                      document.body.removeChild(dummy);
                      setShareButtonText('Link Copied to Clipboard!');
                      analytics.logEvent('share', { content_type: doc.Title, content_id: doc.id });
                      setTimeout(() => {
                        setShareButtonText('Share!');
                      }, 2500);
                    }}>
                    {shareButtonText}
                  </PrimaryButton>
                </Actions>
              </LeftColumn>
              <RightColumn>
              </RightColumn>

            </TwoColumn>
            {doc.Landscape ? <>
              <Container>
                <DocContainer>
                  <LandscapeDocContainer width={parseFloat(width / 95) * 100}>
                    {doc.DocType === 'pdf' && <Document file={docURL} onContextMenu={(e) => e.preventDefault()} onLoadSuccess={onDocumentLoadSuccess}>
                      {Array.from(
                        new Array(numPages),
                        (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            className="pdf-container"
                            pageNumber={index + 1}
                            width={width}
                            scale={zoom}
                          />
                        ),
                      )}
                    </Document>}
                    {doc.DocType === 'img' && <img src={docURL} className="pdf-container" onContextMenu={(e) => e.preventDefault()} width={width} height={'100%'} alt={doc.Title + ' document'} />}
                    {/* <HeaderRow>
                      <PagesControl>
                        <PageControlArrows key='zoom_out' onClick={(e) => (setZoom(zoom - 0.5))}>
                          {'-'}
                        </PageControlArrows>
                        <PageControl>
                          {zoom}
                        </PageControl>
                        <PageControlArrows key='zoom_in' onClick={(e) => (setZoom(zoom + 0.5))}>
                          {'+'}
                        </PageControlArrows>
                      </PagesControl>
                    </HeaderRow> */}
                  </LandscapeDocContainer>
                </DocContainer>
                <DecoratorBlob />
                <DecoratorBlob5 />
                <DecoratorBlob1 />
                <DecoratorBlob3 />
                <DecoratorBlob4 />
              </Container>
            </> :
              <PortraitContainer>
                <PortraitDocContainer>
                  <PortraitDocument>
                    <HeaderRow>
                      <PagesControl>
                        <PageControlArrows key={'left'} disabled={activePage === 1} onClick={(e) => { if (activePage > 1) { setActivePage((activePage > 1) ? activePage - 1 : activePage); } }}>
                          {'<'}
                        </PageControlArrows>
                        <PageControl>
                          {activePage}
                        </PageControl>

                        <PageControlArrows key={'right'} disabled={activePage === doc.PageIndex.length} onClick={(e) => { if (activePage < doc.PageIndex.length) { setActivePage((activePage < doc.PageIndex.length) ? activePage + 1 : activePage); } }}>
                          {'>'}
                        </PageControlArrows>
                      </PagesControl>
                    </HeaderRow>
                    {doc.DocType === 'pdf' ? <Document file={docURL} className="pdf-container" onContextMenu={(e) => e.preventDefault()} onLoadSuccess={onDocumentLoadSuccess}>
                      <Page pageNumber={activePage} className="pdf-container" width={width} />
                    </Document> :
                      <img src={docURL} className="pdf-container" onContextMenu={(e) => e.preventDefault()} width={width} height={'100%'} alt={doc.Title + ' document'} />
                    }
                    <HeaderRow>
                      <PagesControl>
                        <PageControlArrows key={'left'} disabled={activePage === 1} onClick={(e) => { if (activePage > 1) { setActivePage((activePage > 1) ? activePage - 1 : activePage); } }}>
                          {'<'}
                        </PageControlArrows>
                        <PageControl>
                          {activePage}
                        </PageControl>
                        <PageControlArrows key={'right'} disabled={activePage === doc.PageIndex.length} onClick={(e) => { if (activePage < doc.PageIndex.length) { setActivePage((activePage < doc.PageIndex.length) ? activePage + 1 : activePage); } }}>
                          {'>'}
                        </PageControlArrows>
                      </PagesControl>
                    </HeaderRow>
                  </PortraitDocument>
                  <PortraitDocSidebar>
                    <IndexContainer>
                      <IndexHeader>Sections</IndexHeader>
                      {doc.PageIndex && doc.PageIndex.map((link, index) => (
                        <IndexTextContainer key={index}>
                          <IndexText active={activePage === index + 1} onClick={(e) => { setActivePage(index + 1); }}>
                            {index + 1}. {link}
                          </IndexText>
                        </IndexTextContainer>
                      ))}
                    </IndexContainer>
                    <MedianetADS size="300x250" divId="880474553" />
                  </PortraitDocSidebar>
                </PortraitDocContainer>
                <DecoratorBlob1 />
                <DecoratorBlob6 />
                {/* <DecoratorBlob7 /> */}
              </PortraitContainer>
            }
            {relatedResources.length > 0 && <>
              <CardGrid
                id='related'
                headerText="Related Resources"
                tabs={{ All: relatedResources }}
                blobs={false}
              />
            </>}
          </>
          :
          <>{!error && <LoadingAnimation />}</>
        }
        {error && <ErrorText>Hmmm... Something went wrong. Please try the following:<br />You may by offline, check your internet connection and try again. <br />Try refreshing the page or checkout some other resources. If the issue doesn't resolve itself please contact angus@revisehigher.com</ErrorText>}
        <Footer />
      </AnimationRevealPage >
    )
  } else {
    return <Redirect to='/404' />;
  }
}

export default DocumentView;
