import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as FileIcon } from "feather-icons/dist/icons/file.svg";
import { ReactComponent as CheckIcon } from "feather-icons/dist/icons/check-square.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import slugify from 'slugify';
import Card from './Card';

// Search + Filtering css
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)``;

// Card grid css
const Cards = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;

const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-50 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;

function CardGrid({
  headerText = 'Grid Card Component',
  tabs = { All: [] },
  blobs = false,
}) {

  const tabsKeys = Object.keys(tabs);
  const tabsNames = tabsKeys.map((tabKey) => { return tabKey.replace('_', ' ') });
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{headerText}</Header>
          {tabs.length === 1 ?
            <TabsControl>
              {Object.keys(tabs).map((tabName, index) => (
                <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                  {tabsNames[index]}
                </TabControl>
              ))}
            </TabsControl>
            :
            <></>
          }
        </HeaderRow>
        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            <Cards>
              {tabs[tabKey].map((card, index) => (
                <Card
                  key={index}
                  title={card.Title}
                  imageURL={card.ThumbnailURL}
                  secondary_row={<>
                    <IconWithText>
                      <IconContainer>
                        <FileIcon />
                      </IconContainer>
                      <Text>{card.Type}</Text>
                    </IconWithText>
                    <IconWithText>
                      <IconContainer>
                        <CheckIcon />
                      </IconContainer>
                      <Text>{card.Topic}</Text>
                    </IconWithText>
                  </>}
                  primary_href={`/documents/${card.id}/higher-${card.Subject}-${slugify(card.Title.toLowerCase())}`}
                  button_text={'View'}
                  description={card.Description}
                />
              ))}
            </Cards>
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      {blobs && <DecoratorBlob1 />}
    </Container>
  );
}

export default CardGrid;