import React, { useState, useEffect } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { storage, analytics } from '../../config/firebase-config';
import { Link } from 'react-router-dom';

const CardContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
`;

const DisplayCard = tw.div`flex flex-col rounded-lg sm:border sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none h-full`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}"); filter: blur(${props.premium ? "5px" : "0px"}); -webkit-filter: blur(${props.premium ? "5px" : "0px"});`,
  tw`w-full h-56 sm:h-64 bg-cover bg-top rounded sm:rounded-none sm:rounded-tl-4xl`
]);
const ErrorContainer = tw.div`flex items-center w-full h-56 sm:h-64 rounded sm:rounded-none sm:rounded-tl-4xl`;
const ErrorMessage = tw.div`m-auto`;

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const DocumentCount = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Count = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;

const PrimaryLinkContainer = tw.div`mt-auto`;
const PrimaryButton = tw(PrimaryButtonBase)`sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 text-center`;


function Card({
  title = 'Card Title',
  imageURL = null,
  icon = null,
  icon_value = null,
  secondary_row = null,
  primary_href = '/',
  description = '',
  button_text = 'Resources',
  premium = false,
}) {
  const [thumbnail, setThumbnail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (imageURL) {
      const storage_ref = storage.ref(imageURL);
      storage_ref.getDownloadURL().then((url) => {
        setThumbnail(url);
      }).catch(function (err) {
        analytics.logEvent('error', { location: 'Card image', error: err });
        setError(true);
      })
    }
  }, [imageURL])

  return (
    <CardContainer>
      <Link to={primary_href}>
        <DisplayCard className="group">
          {imageURL && !error ? <CardImage imageSrc={thumbnail} premium={premium} /> : <ErrorContainer><ErrorMessage>Failed to load image</ErrorMessage></ErrorContainer>}
          <TextInfo>
            <TitleReviewContainer>
              <Title>{title}</Title>
              {icon ? <DocumentCount>
                {icon}
                <Count>{icon_value}</Count>
              </DocumentCount> : <></>}
            </TitleReviewContainer>
            <SecondaryInfoContainer>
              {secondary_row}
            </SecondaryInfoContainer>
            {description ? <Description>{description}</Description> : <></>}
          </TextInfo>
          <PrimaryLinkContainer>
            <PrimaryButton>{button_text}</PrimaryButton>
          </PrimaryLinkContainer>
        </DisplayCard>
      </Link>
    </CardContainer >
  );
}

export default Card;