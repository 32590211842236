import React from 'react';
import tw from 'twin.macro';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Hero from '../components/hero/TwoColumnWithVideo';
import Footer from 'components/footers/SimpleFiveColumn';
import { Helmet } from 'react-helmet';

function About() {
  const imageCss = tw`rounded-4xl`;

  return (<AnimationRevealPage disabled>
    <Helmet>
      <title>About | Revise Higher</title>
      <meta name='description' content='About Revise Higher' />
    </Helmet>
    <Hero
      heading='About'
      description='Learn more about Revise Higher'
      imageSrc={null}
      imageCss={imageCss}
      primaryButtonUrl='Learn More'
    />
    <Footer />
  </AnimationRevealPage>)
}

export default About;