import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import { ReactComponent as FileIcon } from "feather-icons/dist/icons/file-text.svg";
import { ReactComponent as CheckIcon } from "feather-icons/dist/icons/check-square.svg";
import { ReactComponent as FolderIcon } from "feather-icons/dist/icons/folder.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import { firestore, auth } from 'config/firebase-config';
import firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;

const Details = tw.div`p-6 rounded border-2 rounded border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const EditButton = styled(PrimaryButtonBase).attrs({ as: "a" })`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const ModalContent = tw.div`relative`;

const FormContent = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const FormColumn = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const SecondaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded mt-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800 transition duration-300`;

export default ({
  subheading = "Admin panel",
  heading = <>Edit <span tw="text-primary-500">Resources.</span></>,
}) => {
  const postsRef = firestore.collection('resources');
  const query = postsRef.where('isPublic', '==', true);

  const [posts] = useCollectionData(query, { idField: 'id' });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState('');
  const [charCount, setCharCount] = useState(0);

  // Form state
  const [modalForm, setModalForm] = useState({
    Title: '',
    Description: '',
    Subject: '',
    Topic: '',
    Type: '',
    Unit: '',
  });

  const submit_post = async (e) => {
    e.preventDefault();
    if (modalMode === 'create') {
      await postsRef.add({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        Description: modalForm.Description,
        DocumentURL: 'resources/' + modalForm.Title.replaceAll(' ', '') + '.pdf',
        DocType: 'pdf',
        GridOrder: posts.length,
        Landscape: (modalForm.Landscape === 'true'),
        Subject: modalForm.Subject,
        ThumbnailURL: 'resource-thumbnails/' + modalForm.Title.replaceAll(' ', '') + '.jpg',
        Title: modalForm.Title,
        Topic: modalForm.Topic,
        Type: modalForm.Type,
        Unit: modalForm.Unit,
        isPremium: false,
        isPublic: true,
        featured: false,
        PageIndex: [],
      });
      toggleModal(null);
    } else if (modalMode === 'edit') {
      await postsRef.doc(modalForm.id).update({
        Title: modalForm.Title,
        Description: modalForm.Description,
        Subject: modalForm.Subject,
        Topic: modalForm.Topic,
        Type: modalForm.Type,
        Unit: modalForm.Unit,
        Landscape: modalForm.Landscape,
      });
      toggleModal(null);
    }
  }

  const toggleModal = (e, mode) => {
    if (mode === 'edit') {
      if (e != null) {
        const post = posts[e.target.attributes[0].value];
        setModalForm({
          id: post.id,
          Title: post.Title,
          Description: post.Description,
          Subject: post.Subject,
          Topic: post.Topic,
          Type: post.Type,
          Unit: post.Unit,
          Landscape: post.Landscape,
        });
        setCharCount(post.Description.length);
        setModalMode('edit');
      }
    } else if (mode === 'create') {
      setModalForm({
        Title: '',
        Description: '',
        Subject: '',
        Topic: '',
        Type: '',
        Unit: '',
        Landscape: '',
      });
      setModalMode('create');
    }
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <PrimaryButton onClick={e => toggleModal(null, 'create')}>New Resource</PrimaryButton>
          <SecondaryButton onClick={e => auth.signOut()}>Logout</SecondaryButton>
        </HeadingInfoContainer>
        <ThreeColumn>
          {posts && posts.sort((a, b) => (a.GridOrder > b.GridOrder) ? 1 : -1).map((post, index) => (
            <Column key={index}>
              <Card>
                <Details>
                  <MetaContainer>
                    <Meta>
                      <FolderIcon />
                      <div>{post.Subject.charAt(0).toUpperCase() + post.Subject.slice(1)}</div>
                    </Meta>
                    <Meta>
                      <CheckIcon />
                      <div>{post.Topic}</div>
                    </Meta>
                    <Meta>
                      <FileIcon />
                      <div>{post.Type}</div>
                    </Meta>
                  </MetaContainer>
                  <Title>{post.Title}</Title>
                  <Description>{post.Description}</Description>
                  <EditButton data-key={index} onClick={e => toggleModal(e, 'edit')}>Edit</EditButton>
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
      <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={modalIsOpen}
        onRequestClose={() => toggleModal(null)}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <CloseModalButton onClick={() => toggleModal(null)}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        <div className="content">
          {false ? <h1>{modalForm.Title}</h1> : <></>}
          {modalIsOpen ?
            <ModalContent>
              <FormContent>
                <FormContainer>
                  <div tw="mx-auto max-w-4xl">
                    <h2>Edit {modalForm.Title} - {modalForm.id}</h2>
                    <form onSubmit={submit_post}>
                      <TwoColumn>
                        <FormColumn>
                          <InputContainer>
                            <Label htmlFor="title">Title</Label>
                            <Input id="title" type="text" name="Title" value={modalForm.Title} onChange={e => setModalForm({ ...modalForm, Title: e.target.value })} placeholder="E.g. Differentiation Summary" />
                          </InputContainer>
                          <InputContainer>
                            <Label htmlFor="description">Description - Character count {charCount} (Keep under 160)</Label>
                            <TextArea id="description" type="text" name="Description" value={modalForm.Description} onChange={e => { setModalForm({ ...modalForm, Description: e.target.value }); setCharCount(e.target.value.length) }} placeholder="E.g. Details about the resource" />
                          </InputContainer>
                          <InputContainer>
                            <Label htmlFor="unit">Unit (Underscores)</Label>
                            <Input id="unit" type="text" name="Unit" value={modalForm.Unit} onChange={e => setModalForm({ ...modalForm, Unit: e.target.value })} placeholder="E.g. Unit_1" />
                          </InputContainer>
                        </FormColumn>
                        <FormColumn>
                          <InputContainer>
                            <Label htmlFor="topic">Topic</Label>
                            <Input id="topic" type="text" name="Topic" value={modalForm.Topic} onChange={e => setModalForm({ ...modalForm, Topic: e.target.value })} placeholder="E.g. Differentiation" />
                          </InputContainer>
                          <InputContainer>
                            <Label htmlFor="subject">Subject (Lowercase)</Label>
                            <Input id="subject" type="text" name="Subject" value={modalForm.Subject} onChange={e => setModalForm({ ...modalForm, Subject: e.target.value })} placeholder="E.g. maths" />
                          </InputContainer>
                          <InputContainer>
                            <Label htmlFor="type">Type</Label>
                            <Input id="type" type="text" name="Type" value={modalForm.Type} onChange={e => setModalForm({ ...modalForm, Type: e.target.value })} placeholder="E.g. Summary" />
                          </InputContainer>
                          <InputContainer>
                            <Label htmlFor="landscape">Landscape (true or false)</Label>
                            <Input id="landscape" type="text" name="Landscape" value={modalForm.Landscape} onChange={e => setModalForm({ ...modalForm, Landscape: e.target.value })} placeholder="true" />
                          </InputContainer>
                        </FormColumn>
                      </TwoColumn>

                      <SubmitButton type="submit" value="Submit">Save</SubmitButton>
                    </form>
                  </div>
                </FormContainer>
              </FormContent>
            </ModalContent>
            : <></>}
        </div>
      </StyledModal>
    </Container >
  );
};
