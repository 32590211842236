import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as BookIcon } from "feather-icons/dist/icons/book.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-7.svg";
import PageHeader from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { ReactComponent as FileIcon } from "feather-icons/dist/icons/file-text.svg";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

// Search + Filtering css
const HeaderRow = tw.div`flex`;
const Header = tw(SectionHeading)``;

// Card grid css
const Cards = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const CardContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
`;

const Card = tw.div`flex flex-col rounded-lg sm:border sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none h-full`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryLinkContainer = tw.div`mt-auto`;
const PrimaryButton = tw(PrimaryButtonBase)`sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 text-center`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-50 transform translate-x-2/3 translate-y-24 rotate-180`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none opacity-25 absolute left-0 top-0 h-64 w-64 transform translate-y-6 -translate-x-2/3  -z-10`}
`;
const DecoratorBlob3 = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none -z-30 absolute left-0 bottom-0 h-56 w-56 opacity-50 transform -translate-x-2/3 -translate-y-24 text-teal-400`}
`;

const subjects = [
  {
    subject: 'Maths',
    cover_image: "https://images.unsplash.com/photo-1511377107391-116a9d5d20b5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
    description: 'Course notes and Topic Summaries for the SQA Higher Mathematics course, with worked and annotated examples breaking questions down into core concepts.',
    summaries: true,
  },
  {
    subject: 'Geography',
    cover_image: "https://images.unsplash.com/photo-1519500099198-fd81846b8f03?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
    description: 'Exam Answers and Case Study breakdowns for the SQA Higher Geography course',
    summaries: true,
  },
  {
    subject: 'Computing',
    cover_image: "https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
    description: 'Course notes and Topic Summaries for the SQA Higher Computing Science course',
    summaries: true,
  },
  {
    subject: 'Physics',
    cover_image: "https://images.unsplash.com/photo-1444703686981-a3abbc4d4fe3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
    description: 'Course notes for the SQA Higher Physics course with definitions, worked examples and topic breakdowns.',
    summaries: true,
  },
];

// {
//   subject: 'English',
//     cover_image: "https://images.unsplash.com/photo-1550399105-05c4a7641b02?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
//       description: 'Techniques, quotes and essay structures for all units in the SQA Higher English course',
//         summaries: false,
//   },

function SubjectList() {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Subjects - Revise Higher</title>
        <meta name='description' content='Subjects on Revise Higher' />
        <meta property="og:title" content={'Subjects - Revise Higher'} />
        <meta property="og:description" content={'Subjects on Revise Higher'} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:title" content={'Subjects - Revise Higher'} />
        <meta name="twitter:description" content={'Subjects on Revise Higher'} />
      </Helmet>
      <PageHeader />

      {/* Card Grid */}
      <Container>
        <ContentWithPaddingXl>
          <HeaderRow>
            <Header>Find your <HighlightedText>subject</HighlightedText></Header>
            <DecoratorBlob2 />
          </HeaderRow>

          <Cards>
            {subjects.map((subject, index) => (
              <CardContainer key={index}>
                <Link to={`/subjects/${subject.subject.toLowerCase()}`}>
                  <Card className="group">
                    <CardImage imageSrc={subject.cover_image} />
                    <TextInfo>
                      <TitleReviewContainer>
                        <Title>{subject.subject}</Title>
                      </TitleReviewContainer>
                      <SecondaryInfoContainer>
                        {subject.summaries ? <IconWithText>
                          <IconContainer>
                            <FileIcon />
                          </IconContainer>
                          <Text>Topic Summaries</Text>
                        </IconWithText> : <></>}
                        <IconWithText>
                          <IconContainer>
                            <BookIcon />
                          </IconContainer>
                          <Text>Notes</Text>
                        </IconWithText>
                      </SecondaryInfoContainer>
                      <Description>{subject.description}</Description>
                    </TextInfo>
                    <PrimaryLinkContainer>
                      <PrimaryButton>Resources</PrimaryButton>
                    </PrimaryLinkContainer>
                  </Card>
                </Link>
              </CardContainer>
            ))}
          </Cards>
        </ContentWithPaddingXl>
        <DecoratorBlob1 />
      </Container>

      <Footer />
    </AnimationRevealPage >
  );
}

export default SubjectList;
