import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import { SectionHeading } from "components/misc/Headings";
import { Helmet } from 'react-helmet';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Terms of Service - Revise Higher</title>
        <meta name='description' content='Terms of Service for Revise Higher' />
      </Helmet>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <h2>Terms and Conditions of <span className="website_url">revisehigher.com</span></h2>

            <p>The following terms and conditions (collectively, these "Terms and Conditions") apply to your use of <span className="website_url">revisehigher.com</span>, including any content, functionality and services offered on or via <span className="website_url">revisehigher.com</span> (the "Website").</p>

            <p>Please read the Terms and Conditions carefully before you start using <span className="website_name">Revise Higher</span>, because by using the Website you accept and agree to be bound and abide by these Terms and Conditions.</p>

            <p>These Terms and Conditions are effective as of <span className="date">6th December 2020</span>. We expressly reserve the right to change these Terms and Conditions from time to time without notice to you. You acknowledge and agree that it is your responsibility to review this Website and these Terms and Conditions from time to time and to familiarize yourself with any modifications. Your continued use of this Website after such modifications will constitute acknowledgement of the modified Terms and Conditions and agreement to abide and be bound by the modified Terms and Conditions.</p>

            <h3>Intellectual Property</h3>

            <p>By accepting these Terms and Conditions, you acknowledge and agree that all content presented to you on this Website is protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws, and is the sole property of <span className="website_name">Revise Higher</span>.</p>

            <p>You are only permitted to use the content as expressly authorized by us or the specific content provider. Except for a single copy made for personal use only, you may not copy, reproduce, modify, republish, upload, post, transmit, or distribute any documents or information from this Website in any form or by any means without prior written permission from us or the specific content provider, and you are solely responsible for obtaining permission before reusing any copyrighted material that is available on this Website.</p>

            <h3>Third Party Websites</h3>

            <p>This Website may link you to other sites on the Internet or otherwise include references to information, documents, software, materials and/or services provided by other parties. These websites may contain information or material that some people may find inappropriate or offensive.</p>

            <p>These other websites and parties are not under our control, and you acknowledge that we are not responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the content of such sites, nor are we responsible for errors or omissions in any references to other parties or their products and services. The inclusion of such a link or reference is provided merely as a convenience and does not imply endorsement of, or association with, the Website or party by us, or any warranty of any kind, either express or implied.</p>

            <h3>Disclaimer of Warranties, Limitations of Liability and Indemnification</h3>

            <p>Your use of <span className="website_name">Revise Higher</span> is at your sole risk. The Website is provided "as is" and "as available". We disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement.</p>

            <p>We are not liable for damages, direct or consequential, resulting from your use of the Website, and you agree to defend, indemnify and hold us harmless from any claims, losses, liability costs and expenses (including but not limites to attorney's fees) arising from your violation of any third-party's rights. You acknowledge that you have only a limited, non-exclusive, nontransferable license to use the Website. Because the Website is not error or bug free, you agree that you will use it carefully and avoid using it ways which might result in any loss of your or any third party's property or information.</p>

            <h3>Term and termination</h3>

            <p>This Terms and Conditions will become effective in relation to you when you create a <span className="website_name">Revise Higher</span> account or when you start using the <span className="website_name">Revise Higher</span> and will remain effective until terminated by you or by us. </p>

            <p><span className="website_name">Revise Higher</span> reserves the right to terminate this Terms and Conditions or suspend your account at any time in case of unauthorized, or suspected unauthorized use of the Website whether in contravention of this Terms and Conditions or otherwise. If <span className="website_name">Revise Higher</span> terminates this Terms and Conditions, or suspends your  account for any of the reasons set out in this section, <span className="website_name">Revise Higher</span> shall have no liability or responsibility to you.</p>

            <h3>Assignment</h3>

            <p><span className="website_name">Revise Higher</span> may assign this Terms and Conditions or any part of it without restrictions. You may not assign this Terms and Conditions or any part of it to any third party.</p>

            <h3>Governing Law</h3>

            <p>These Terms and Conditions and any dispute or claim arising out of, or related to them, shall be governed by and construed in accordance with the internal laws of the <span className="country">gb</span> without giving effect to any choice or conflict of law provision or rule.</p>

            <p>Any legal suit, action or proceeding arising out of, or related to, these Terms of Service or the Website shall be instituted exclusively in the Courts of <span className="country">GB</span>.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
