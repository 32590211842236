import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { motion } from "framer-motion";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl2 } from "components/misc/Layouts";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as CheckIcon } from "feather-icons/dist/icons/check-square.svg";
import { ReactComponent as FileIcon } from "feather-icons/dist/icons/file-text.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import PageHeader from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Card from 'components/cards/Card';
import Hero from '../components/hero/TwoColumnWithVideo';
import { firestore, storage, analytics } from '../config/firebase-config';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import LoadingAnimation from 'components/animations/Loading';

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const imageCss = tw`rounded-4xl`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

const Post = tw.a`relative lg:w-6/12 flex flex-col h-full rounded focus:outline-none border bg-gray-100`;
const PostImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-32 bg-center bg-cover`}
`;
const PostText = tw.div`flex-1 px-6 py-8`
const PostTitle = tw.h5`text-2xl font-bold group-hocus:text-primary-500 transition duration-300`;
const PostDescription = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const PostContainer = styled.div`
  ${tw`relative z-20 mb-10 w-full`}

  ${props => props.featured && css`
    ${tw`w-full sm:w-full`}
    ${Post} {
      ${tw`flex-row items-center w-full pr-3 rounded-3xl`}
    }
    ${PostImage} {
      ${tw`h-32 min-h-full w-1/3 rounded-tl-3xl rounded-bl-3xl`}
    }
    ${PostText} {
      ${tw`pl-8 pr-5`}
    }
  `}
`;

// Search + Filtering css
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-50 transform -translate-x-2/3 translate-y-3 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-2/3 text-primary-500`}
`;

const TabContent = tw(motion.div)``;

// Card grid css
const Cards = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;

const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const ErrorText = tw.p`ml-2 text-lg font-semibold text-gray-800 text-center my-16`;

const subjects = {
  'maths': {
    subject: 'Maths',
    cover_image: "https://images.unsplash.com/photo-1511377107391-116a9d5d20b5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
    description: 'Course notes and Topic Summaries for the SQA Higher Mathematics course, with worked and annotated examples breaking questions down into core concepts.',
    summaries: true,
    notes: true,
  },
  'geography': {
    subject: 'Geography',
    cover_image: "https://images.unsplash.com/photo-1519500099198-fd81846b8f03?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
    description: 'Exam Answers and Case Study breakdowns for the SQA Higher Geography course',
    summaries: true,
    notes: true,
  },
  'physics': {
    subject: 'Physics',
    cover_image: "https://images.unsplash.com/photo-1444703686981-a3abbc4d4fe3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
    description: 'Course notes for the SQA Higher Physics course with definitions, worked examples and topic breakdowns.',
    summaries: false,
    notes: true,
  },
  'computing': {
    subject: 'Computing',
    cover_image: "https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
    description: 'Course notes for the SQA Higher Computing course',
    summaries: true,
    notes: true,
  },
  'english': {
    subject: 'English',
    cover_image: "https://images.unsplash.com/photo-1550399105-05c4a7641b02?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
    description: 'Techniques, quotes and essay structures for all units in the SQA Higher English course',
    summaries: false,
    notes: true,
  },
};

function Subject(props) {
  const [tabs, setTabs] = useState({});
  const [documents, setDocuments] = useState([]);
  const [description, setDescription] = useState('');
  const [featuredResourcePath, setFeaturedResourcePath] = useState(null);
  const [featuredResource, setFeaturedResource] = useState(false);
  const [featuredResourceLoaded, setFeaturedResourceLoaded] = useState(false);
  const [featuredThumbnail, setFeaturedThumbnail] = useState(null);
  const [error, setError] = useState(false);

  // Get section titles
  useEffect(() => {
    firestore.collection('subjects').doc(props.match.params.subject).get()
      .then(function (doc) {
        if (doc.exists) {
          const data = doc.data();
          let t = { All: [] };
          for (const unit of data.Units) {
            t[unit] = [];
          }
          setTabs(t);
          setDescription(data.Description);
          if (data.FeaturedResource !== undefined) {
            setFeaturedResourcePath(data.FeaturedResource);
          } else {
            setFeaturedResourceLoaded(true);
          }
        } else {
          setError(true);
        }
      })
      .catch(err => {
        analytics.logEvent('error', { location: 'Subject titles', error: err });
        setError(true);
      })
  }, [props.match.params.subject]);

  // Get documents
  useEffect(() => {
    firestore.collection('resources')
      .where('Subject', '==', props.match.params.subject)
      .where('isPublic', '==', true)
      .where('featured', '==', false)
      .limit(25)
      .get()
      .then(function (querySnapshot) {
        let d = [];
        querySnapshot.forEach(function (doc) {
          let temp_d = doc.data();
          temp_d.id = doc.id;
          d.push(temp_d);
        });
        d.sort((a, b) => (a.GridOrder > b.GridOrder) ? 1 : -1);
        setDocuments(d);
      })
      .catch(function (err) {
        analytics.logEvent('error', { location: 'Subject documents', error: err });
        setError(true);
      });
  }, [props.match.params.subject]);

  useEffect(() => {
    if (featuredResourcePath && !featuredResourceLoaded) {
      firestore.collection(featuredResourcePath.o_.K_.path.segments[5]).doc(featuredResourcePath.o_.K_.path.segments[6]).get().then(
        function (doc) {
          if (doc.exists) {
            let d = doc.data();
            d.id = doc.id;
            setFeaturedResource(d);
            setFeaturedResourceLoaded(true);
          } else {
            setFeaturedResourceLoaded(true);
          }

          if (doc.data().ThumbnailURL) {
            const storage_ref = storage.ref(doc.data().ThumbnailURL);
            storage_ref.getDownloadURL().then((url) => {
              setFeaturedThumbnail(url);
            }).catch(function (err) {
              analytics.logEvent('error', { location: 'Featured card image', error: err });
            });
          }
        }
      )
    }
  }, [featuredResourcePath]);

  useEffect(() => {
    let new_tabs = { ...tabs };
    for (const doc of documents) {
      if (doc.Unit !== 'Resource') {
        new_tabs[doc.Unit].push(doc);
        new_tabs['All'].push(doc);
      }
    }
    setTabs(new_tabs);
  }, [documents]);

  const [tabsKeys, setTabsKeys] = useState(Object.keys(tabs));
  const [activeTab, setActiveTab] = useState('All');

  useEffect(() => {
    setActiveTab(Object.keys(tabs)[0]);
    setTabsKeys(Object.keys(tabs));
  }, [tabs]);
  const tabsNames = tabsKeys.map((tabKey) => { return tabKey.replace('_', ' ') });
  return (
    <AnimationRevealPage disabled>
      {featuredResourceLoaded ? <>
        {featuredResource ?
          <Hero
            heading={<>Higher <HighlightedText>{props.match.params.subject}</HighlightedText></>}
            description={subjects[props.match.params.subject].description}
            shareButton={true}
            subject={props.match.params.subject}
            summaries={subjects[props.match.params.subject].summaries}
            notes={subjects[props.match.params.subject].notes}
            customRightColumn={
              <PostContainer featured={true} key={1}>
                <Link to={'/documents/' + featuredResource.id + '/' + slugify(featuredResource.Title.toLowerCase())}>
                  <Post className="group">
                    <PostImage imageSrc={featuredThumbnail} />
                    <PostText>
                      <PostTitle>{featuredResource.Title}</PostTitle>
                      <PostDescription>{featuredResource.Description}</PostDescription>
                    </PostText>
                  </Post>
                </Link>
              </PostContainer>
            }
          />
          :
          <Hero
            heading={<>Higher <HighlightedText>{props.match.params.subject}</HighlightedText></>}
            description={subjects[props.match.params.subject].description}
            shareButton={true}
            imageSrc={subjects[props.match.params.subject].cover_image}
            imageCss={imageCss}
            imageDecoratorBlob={true}
            subject={props.match.params.subject}
            summaries={subjects[props.match.params.subject].summaries}
            notes={subjects[props.match.params.subject].notes}
          />
        }
      </> :
        <Hero
          heading={<>Higher <HighlightedText>{props.match.params.subject}</HighlightedText></>}
          description={subjects[props.match.params.subject].description}
          shareButton={true}
          subject={props.match.params.subject}
          summaries={subjects[props.match.params.subject].summaries}
          notes={subjects[props.match.params.subject].notes}
        />
      }
      {
        description && <Helmet>
          <title>Higher {props.match.params.subject.charAt(0).toUpperCase() + props.match.params.subject.slice(1)} revision resources | Revise Higher</title>
          <meta name="description" content={subjects[props.match.params.subject].description} />
          <meta property="og:title" content={subjects[props.match.params.subject].subject + ' - Revise Higher'} />
          <meta property="og:description" content={subjects[props.match.params.subject].description} />
          <meta property="og:image" content={subjects[props.match.params.subject].cover_image} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:title" content={subjects[props.match.params.subject].subject + ' - Revise Higher'} />
          <meta name="twitter:description" content={subjects[props.match.params.subject].description} />
          <meta name="twitter:image" content={subjects[props.match.params.subject].cover_image} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      }
      {/* Card Grid */}
      <Container>
        <ContentWithPaddingXl2>
          <HeaderRow>
            <Header>Resources for <HighlightedText>{props.match.params.subject}</HighlightedText></Header>
            {Object.keys(tabs).length > 0 && <TabsControl>
              {Object.keys(tabs).map((tabName, index) => (
                <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                  {tabsNames[index]}
                </TabControl>
              ))}
            </TabsControl>}
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </HeaderRow>
          {Object.keys(tabs).length > 0 && documents.length > 0 && tabs[activeTab].length === 0 &&
            <ErrorText>There currently aren't any resources here.<br />Check back soon, new resources are being added all the time!</ErrorText>
          }
          {Object.keys(tabs).length > 0 ? tabsKeys.map((tabKey, index) => (
            <TabContent
              key={index}
              variants={{
                current: {
                  opacity: 1,
                  scale: 1,
                  display: "flex",
                },
                hidden: {
                  opacity: 0,
                  scale: 0.8,
                  display: "none",
                }
              }}
              transition={{ duration: 0.4 }}
              initial={activeTab === tabKey ? "current" : "hidden"}
              animate={activeTab === tabKey ? "current" : "hidden"}
            >
              {typeof tabs[tabKey] !== 'undefined' && <Cards>
                {tabs[tabKey].map((card, index) => (
                  <Card
                    key={index}
                    title={card.Title}
                    imageURL={card.ThumbnailURL}
                    secondary_row={<>
                      <IconWithText>
                        <IconContainer>
                          <FileIcon />
                        </IconContainer>
                        <Text>{card.Type}</Text>
                      </IconWithText>
                      <IconWithText>
                        <IconContainer>
                          <CheckIcon />
                        </IconContainer>
                        <Text>{card.Topic}</Text>
                      </IconWithText>
                    </>}
                    primary_href={`/documents/${card.id}/higher-${card.Subject}-${slugify(card.Title.toLowerCase())}`}
                    button_text={'View'}
                    description={card.Description}
                  />
                ))}
              </Cards>}
            </TabContent>
          )) : <>{!error && <LoadingAnimation />}</>}
          {error && <ErrorText>Hmmm... Something went wrong. Please try the following:<br />You may by offline, check your internet connection and try again. <br />Try refreshing the page or checkout some other resources. If the issue doesn't resolve itself please contact angus@revisehigher.com</ErrorText>}
        </ContentWithPaddingXl2>
      </Container>

      <Footer />
    </AnimationRevealPage >
  );
}

export default Subject;
