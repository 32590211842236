import React from 'react';
import tw from "twin.macro";
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Hero from '../components/hero/TwoColumnWithVideo';
import FeaturedSubjects from '../components/features/VerticalFeature';
import CardGrid from '../components/cards/CardGrid';
import Footer from 'components/footers/SimpleFiveColumn';
import { Helmet } from 'react-helmet';

function Index() {
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-4xl`;

  const images = [
    'https://images.unsplash.com/flagged/photo-1569744068983-6dfc2f27deb8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80',
    'https://images.unsplash.com/photo-1444703686981-a3abbc4d4fe3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    'https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80',
  ]

  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Higher Revision Notes and Summaries | Revise Higher</title>
        <meta name="description" content="SQA Higher revision notes and topic summaries, freely available for a breadth of subjects, to assist in preparation for your higher exams." />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ach_henderson" />
        <meta name="twitter:title" content="Higher Revision Notes and Summaries | Revise Higher" />
        <meta name="twitter:description" content="SQA Higher revision notes and topic summaries, freely available for a breadth of subjects, to assist in preparation for your higher exams." />
        <meta name="twitter:image" content={window.location.origin + '/IndexImage.png'} />
        <meta name="twitter:image:alt" content="Preview of the home page of Revise Higher" />
        <meta property="og:title" content="Higher Revision Notes and Summaries | Revise Higher" />
        <meta property="og:description" content="SQA Higher revision notes and topic summaries, freely available for a breadth of subjects, to assist in preparation for your higher exams." />
        <meta property="og:image" content={window.location.origin + '/IndexImage.png'} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Hero
        heading={<>High Quality Higher <HighlightedText>Revision Notes.</HighlightedText></>}
        description="SQA Higher revision notes and topic summaries, freely available for a breadth of subjects, to assist in preparation for your higher exams."
        imageSrc={images[2]}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Find your subject"
        primaryButtonUrl="/subjects"
      />
      <FeaturedSubjects />
      <CardGrid
        headerText="Featured Resources"
        tabs={{
          All: [
            {
              id: 'bZmlzlbvfBh3G46oiBbu',
              Title: 'Differentiation Summary',
              Description: 'A summary of the differentiation topic in unit 1 of Higher Maths, all conveniently on one page, from the essential rules of differentiation to curve sketching and the chain rule.',
              Subject: 'maths',
              Topic: 'Maths',
              Type: 'Summary',
              ThumbnailURL: 'resource-thumbnails/DifferentiationSummary.jpg',
            },
            {
              id: 'JCqKqnfaYnPxFDIHIqwX',
              Title: 'Vectors Summary',
              Description: 'A summary of the Vectors topic in unit 1 of Higher Maths, all on one page, from proving collinearity to finding the angle between vectors.',
              Subject: 'maths',
              Topic: 'Maths',
              Type: 'Summary',
              ThumbnailURL: 'resource-thumbnails/VectorsSummary.jpg',
            },
            {
              id: 'ZEwrDzgWLsMn4lERknke',
              Title: 'Mumbai ELDC Case Study',
              Description: 'Housing and traffic problems, solutions for Mumbai and Dharavi, an ELDC. A summary of the Mumbai case study from the Human Unit of Higher Geography.',
              Subject: 'geography',
              Topic: 'Geography',
              Type: 'Summary',
              ThumbnailURL: 'resource-thumbnails/MumbaiELDCCaseStudy.jpg',
            }
          ]
        }}
      />
      <Footer />
    </AnimationRevealPage>
  )
}

export default Index;
