import React, { useState } from 'react';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import logo from "images/logo.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import illustration from "images/login-illustration.svg";
import Header from "components/headers/light.js";
import AdminList from "components/admin/DocumentList.js";
import Footer from "components/footers/SimpleFiveColumn";
import { auth } from 'config/firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Helmet } from 'react-helmet';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const ErrorMessage = tw.div`py-4 bg-red-600 w-full text-white text-center mb-3`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleForm = e => {
    e.preventDefault();
    auth.signInWithEmailAndPassword(email, password).then(res => {
    }).catch(e => {
      setError('Email or password is incorrect!');
    });
  };

  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Admin Panel | Revise Higher</title>
      </Helmet>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href='/'>
              <LogoImage src={logo} alt={'Revise Higher website logo'} />
            </LogoLink>
            <MainContent>
              <Heading>Sign in to admin panel</Heading>
              <FormContainer>
                {error !== null && <ErrorMessage>{error}</ErrorMessage>}
                <Form onSubmit={e => handleForm(e)}>
                  <Input
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    name="email"
                    type="email"
                    placeholder="email"
                  />
                  <Input
                    onChange={e => setPassword(e.target.value)}
                    name="password"
                    value={password}
                    type="password"
                    placeholder="password"
                  />
                  <SubmitButton type="submit">
                    <LoginIcon className="icon" />
                    <span className="text">Sign In</span>
                  </SubmitButton>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustration} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
}

function SignOut() {
  // If user is signed in, return a sign out button
  return auth.currentUser && (
    <button onClick={() => auth.signOut()}>Sign Out</button>
  )
}

function AdminPanel() {
  return (<AnimationRevealPage disabled>
    <Header />
    <AdminList />
    <Footer />
  </AnimationRevealPage>);
}

function Admin() {
  const [user] = useAuthState(auth);

  return (<>
    {user ? <AdminPanel /> : <Login />}
  </>)
}

export default Admin;
