import React, { useEffect } from 'react';
import {
  Route, Switch, Redirect, useLocation, withRouter, Link
} from 'react-router-dom';
import Index from './pages/Index';
import SubjectList from './pages/SubjectList';
import Subject from './pages/Subject';
import DocumentView from './pages/DocumentView';
import About from './pages/About';
import Admin from './pages/Admin';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import NotFound404 from './pages/NotFound404';
import CookieConsent from 'react-cookie-consent';
import tw from "twin.macro";
import './style.css';

const LinkText = tw.p`underline inline hocus:text-primary-200 transition duration-300`;

function App() {
  function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
  }

  const ScrollToTop = withRouter(_ScrollToTop);

  return (
    <div className="App">
      <ScrollToTop>
        <Switch>
          <Route path='/' component={Index} exact />
          <Route path='/subjects' component={SubjectList} exact />
          <Route path='/subjects/:subject' render={(props) => {
            return (['maths', 'geography', 'physics', 'computing'].includes(props.match.params.subject) ? <Subject {...props} /> : <Redirect to='/404' />)
          }} exact />
          <Route path='/documents/:id' render={(props) => {
            return (<DocumentView {...props} />)
          }} />
          <Route path='/admin' component={Admin} exact />
          <Route path='/privacy-policy' component={PrivacyPolicy} exact />
          <Route path='/terms-of-service' component={TermsOfService} exact />
          {/* <Route path='/about' component={About} exact /> */}
          <Route path='*' component={NotFound404} exact />
        </Switch>
        <CookieConsent
          location='bottom'
          buttonStyle={{ backgroundColor: '#6415FF', color: '#FFFFFF' }}
        >
          We use cookies to provide you with a better experience. This includes personalising content and advertising. By continuing to browse this site, you are agreeing to our Use of Cookies. <LinkText><Link to='/privacy-policy'>Learn more</Link></LinkText>
        </CookieConsent>
      </ScrollToTop>
    </div>
  );
}

export default App;
