import React from 'react';
import ReactLoading from 'react-loading';
import tw from "twin.macro";

const Container = tw.div`flex justify-center my-64`;

function Loading(props) {
  return (
    <Container>
      <ReactLoading type={"bars"} color={"#6415FF"} />
    </Container>
  );
}

export default Loading;