import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import Header from "../headers/light.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as BookIcon } from "feather-icons/dist/icons/book-open.svg";
import { ReactComponent as FileIcon } from "feather-icons/dist/icons/file-text.svg";
import { Link } from 'react-router-dom';
import { analytics } from '../../config/firebase-config';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative lg:w-6/12 mt-12 lg:mt-0 flex flex-wrap justify-center`;

const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;
const FeaturesContainer = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const FeaturesText = tw.div`ml-2 font-semibold text-gray-800`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-0 md:opacity-25 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-0 md:opacity-50 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;
// const DecoratorBlob3 = styled(SvgDecoratorBlob3)`
//   ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-50 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
// `;

export default ({
  heading = "Modern React Templates, Just For You",
  description = "Our templates are easy to setup, understand and customize. Fully modular components with a variety of pages and components.",
  primaryButtonText = "Get Started",
  primaryButtonUrl = "#",
  imageSrc = null,
  imageCss = null,
  imageDecoratorBlob = false,
  shareButton = null,
  subject = null,
  summaries = false,
  notes = false,
  customRightColumn = null,
}) => {
  const [shareButtonText, setShareButtonText] = useState('Share!');

  return (
    <>
      <Header />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <FeaturesContainer>
              {summaries && <IconWithText>
                <IconContainer>
                  <FileIcon />
                </IconContainer>
                <FeaturesText>Topic Summaries</FeaturesText>
              </IconWithText>}
              {notes && <IconWithText>
                <IconContainer>
                  <BookIcon />
                </IconContainer>
                <FeaturesText>Notes</FeaturesText>
              </IconWithText>}
            </FeaturesContainer>
            <Paragraph>{description}</Paragraph>
            <Actions>
              {shareButton ? <PrimaryButton
                as="a"
                alt={window.location.href}
                id='share_button'
                onClick={() => {
                  var dummy = document.createElement('input'), text = window.location.href;
                  document.body.appendChild(dummy);
                  dummy.value = text;
                  dummy.select();
                  document.execCommand('copy');
                  document.body.removeChild(dummy);
                  setShareButtonText('Link Copied to Clipboard!');
                  analytics.logEvent('share', { content_type: subject, content_id: subject });
                  setTimeout(() => {
                    setShareButtonText('Share!');
                  }, 2500);
                }}>
                {shareButtonText}
              </PrimaryButton> :
                <Link to={primaryButtonUrl}>
                  <PrimaryButton>{primaryButtonText}</PrimaryButton>
                </Link>
              }
            </Actions>
          </LeftColumn>
          <RightColumn>
            {customRightColumn ?
              <>{customRightColumn}</>
              :
              <IllustrationContainer>
                {imageSrc && <img
                  css={imageCss}
                  src={imageSrc}
                  alt="Hero"
                />}
                {imageDecoratorBlob && <DecoratorBlob2 />}
              </IllustrationContainer>
            }
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
