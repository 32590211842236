import React from 'react';
import tw from 'twin.macro';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import Header from 'components/headers/light';
import CardGrid from 'components/cards/CardGrid';
import Footer from 'components/footers/SimpleFiveColumn';
import { Helmet } from 'react-helmet';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;


function NotFound404() {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Page not found - Revise Higher</title>
        <meta name='description' content='404 error, page not found' />
      </Helmet>
      <Header />
      <Container>
        <Content>
          <HeadingInfoContainer>
            <Subheading>Error 404</Subheading>
            <HeadingTitle>Page not found</HeadingTitle>
            <HeadingDescription>The page your looking for doesn't appear to exist. The content your looking for may have been moved or deleted, or you may have mis-typed the link.</HeadingDescription>
          </HeadingInfoContainer>
          <CardGrid
            headerText="Featured Resources"
            tabs={{
              All: [
                {
                  id: 'bZmlzlbvfBh3G46oiBbu',
                  Title: 'Differentiation Summary',
                  Description: 'A summary of the differentiation topic in unit 1 of Higher Maths, all conveniently on one page, from the essential rules of differentiation to curve sketching and the chain rule.',
                  Subject: 'maths',
                  Topic: 'Maths',
                  Type: 'Summary',
                  ThumbnailURL: 'resource-thumbnails/DifferentiationSummary.png',
                },
                {
                  id: 'JCqKqnfaYnPxFDIHIqwX',
                  Title: 'Vectors Summary',
                  Description: 'A summary of the Vectors topic in unit 1 of Higher Maths, all on one page, from proving collinearity to finding the angle between vectors.',
                  Subject: 'maths',
                  Topic: 'Maths',
                  Type: 'Summary',
                  ThumbnailURL: 'resource-thumbnails/VectorsSummary.png',
                },
                {
                  id: 'ZEwrDzgWLsMn4lERknke',
                  Title: 'Mumbai ELDC Case Study',
                  Description: 'Housing and traffic problems and solutions for Mumbai and Dharavi, an ELDC. A summary of the Mumbai case study covering the key points covering the need for solutions, the problems and solutions and their effectivness.',
                  Subject: 'geography',
                  Topic: 'Geography',
                  Type: 'Summary',
                  ThumbnailURL: 'resource-thumbnails/MumbaiELDCCaseStudy.png',
                }
              ]
            }}
          />
        </Content>
      </Container>
      <Footer />
    </AnimationRevealPage>
  )
}

export default NotFound404;